/* eslint-disable react/jsx-no-literals */

import React, { useState } from 'react'
import { Layout } from 'components/Layout'
import { Steps } from 'components/Wizard'
import {
  TableWrapper,
  FormSimulationWrapper,
  FormInformationSimulationWrapper,
  WizardWrapper,
  WizardLabelWrapper,
} from 'components/Wrappers/wrappers.style'
import { ButtonIndexClientInformation } from 'components/Button'
import { ArrowIcon, ComparIcon, EqualIcon } from 'components/Icons'
import { H2Style, H3Style } from 'components/Title/Title.style'
import { FormSimulationSimple } from 'components/Form/FormSimulationSimple'
import { FormSimulationSimpleProposal } from 'components/Form/FormSimulationSimpleProposal'
import { FormSimulationSimpleRate } from 'src/components/Form/FormSimulationSimpleRate'
import { FormSimulationSimpleComparation } from 'components/Form/FormSimulationSimpleComparation'
import { ButtonSaveProposal } from 'components/Button/ButtonSaveProposal'
import { DataViewSimulation } from 'components/DataView/DataViewSimulation'
import { PrivateRoute } from 'components/PrivateRoute'
import { Auth } from 'components/Auth'

const Simulation = () => {
  const [enableSubmit, setEnableSubmit] = useState(false)

  return (
    <Auth>
      <PrivateRoute>
        <Layout>
          <div style={{ padding: '30px' }}>
            <TableWrapper>
              <div>
                <ArrowIcon navigateTo="/" />
                <H2Style>
                  <h2>Simulação</h2>
                </H2Style>
              </div>
              <WizardWrapper>
                <Steps current={2} />
                <WizardLabelWrapper>
                  <ButtonIndexClientInformation disabled>
                    consulta
                  </ButtonIndexClientInformation>
                  <ButtonIndexClientInformation>
                    simulação
                  </ButtonIndexClientInformation>
                  <ButtonIndexClientInformation disabled>
                    visão geral
                  </ButtonIndexClientInformation>
                </WizardLabelWrapper>
              </WizardWrapper>
              <FormSimulationWrapper>
                <div>
                  <H3Style>
                    <h3>Simular</h3>
                  </H3Style>
                </div>
                <div>
                  <FormSimulationSimple />
                </div>
              </FormSimulationWrapper>
              <FormInformationSimulationWrapper>
                <div>
                  <H3Style>
                    <h3>Taxas</h3>
                  </H3Style>
                </div>
                <div>
                  <FormSimulationSimpleRate />
                </div>
              </FormInformationSimulationWrapper>
              <FormInformationSimulationWrapper>
                <div>
                  <H3Style>
                    <h3>Proposta</h3>
                  </H3Style>
                </div>
                <div>
                  <FormSimulationSimpleProposal />
                </div>
              </FormInformationSimulationWrapper>
              <FormInformationSimulationWrapper>
                <div>
                  <H3Style>
                    <h3>Comparativo</h3>
                  </H3Style>
                </div>
                <div>
                  <FormSimulationSimpleComparation />
                  <ComparIcon />
                  <EqualIcon />
                </div>
              </FormInformationSimulationWrapper>
              <div>
                <DataViewSimulation enabledSubmit={setEnableSubmit} />
              </div>
              <div>
                <ButtonSaveProposal
                  navigateTo="/informacoes-do-cliente"
                  disabled={!enableSubmit}
                >
                  Formalizar Proposta
                </ButtonSaveProposal>
              </div>
            </TableWrapper>
          </div>
        </Layout>
      </PrivateRoute>
    </Auth>
  )
}

export default Simulation
