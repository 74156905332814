import { css } from '@creditas/stylitas'

export const cellTable = css`
  border-top: solid rgba(0, 0, 0, 0.12);
  border-bottom: solid rgba(0, 0, 0, 0.12);
  font: 400 14px / normal 'Work Sans', sans-serif;
  :hover {
    background-color: rgba(0, 0, 0, 0.12);
  }
`
export const tableStyle = css`
  box-sizing: border-box;
  justify-content: center;
  margin-left: 900px;
  margin-right: 100px;
  margin-top: -85px;
  :hover {
  }
`

// export const CellTableStyle = css`
//   text-align: left !important;
//   vertical-align: middle;
//   position: relative;
//   height: 48px;
//   border-top: 1px solid rgba(0, 0, 0, 0.12);
//   border-bottom: 1px solid rgba(0, 0, 0, 0.12);
//   padding: 12px 18px;
//   box-sizing: border-box;
//   font-size: 16px;

//   :hover {
//     border: none;
//     border-bottom: 1px solid #2e8;
//   }

//   :focus-within {
//     border: none;
//     border-bottom: 1px solid #2e8;
//     color: rgba(74, 74, 74, 0.54);
//     transform-origin: 0 0;
//     text-overflow: ellipsis;
//   }
// ` stripedColorOdd="#e0e0e0"
