/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-literals */
import React, { useContext } from 'react'
import { ApplicationContext } from 'context/Application'
import { Table, TableBody, TableCell } from '@creditas/table'
import { H3TableHeadStyle } from 'components/Title/Title.style'
import { cellTable, tableStyle } from './DataView.style'

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config)

  const sortedItems = React.useMemo(() => {
    const sortableItems = [...items]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return sortableItems
  }, [items, sortConfig])

  const requestSort = key => {
    let direction = 'ascending'
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
  }

  return { items: sortedItems, requestSort, sortConfig }
}

const ProductTable = props => {
  const { items, requestSort, sortConfig } = useSortableData(props.products)
  const getClassNamesFor = name => {
    if (!sortConfig) {
      return
    }
    return sortConfig.key === name ? sortConfig.direction : undefined
  }
  return (
    <Table css={tableStyle}>
      <caption
        style={{
          padding: '5px',
          color: 'rgba(74, 74, 74, 0.54)',
          display: 'inline-flex',
          position: 'relative',
          borderRadius: '4',
          borderBottom: '2px solid #2e8',
          fontFamily: 'Work Sans, sans-serif',
          fontSize: '14px',
          fontWeight: '550',
          textTransform: 'uppercase',
          bottom: '65px',
        }}
      >
        Certificados
      </caption>
      <thead>
        <tr>
          <th>
            <button
              type="button"
              onClick={() => requestSort('certificateId')}
              className={getClassNamesFor('certificateId')}
              style={{
                backgroundColor: '#ccff22',
                borderRadius: '4px',
                textTransform: 'uppercase',
                border: 'none',
                boxShadow:
                  '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
              }}
            >
              <H3TableHeadStyle>ID</H3TableHeadStyle>
            </button>
          </th>
          <th>
            <button
              type="button"
              onClick={() => requestSort('plan')}
              className={getClassNamesFor('plan')}
              style={{
                backgroundColor: '#ccff22',
                borderRadius: '4px',
                textTransform: 'uppercase',
                border: 'none',
                boxShadow:
                  '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
              }}
            >
              <H3TableHeadStyle>Plano</H3TableHeadStyle>
            </button>
          </th>
          <th>
            <button
              type="button"
              onClick={() => requestSort('fundName')}
              className={getClassNamesFor('fundName')}
              style={{
                backgroundColor: '#ccff22',
                borderRadius: '4px',
                textTransform: 'uppercase',
                border: 'none',
                boxShadow:
                  '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
              }}
            >
              <H3TableHeadStyle>Fundos</H3TableHeadStyle>
            </button>
          </th>
          <th>
            <button
              type="button"
              onClick={() => requestSort('reservedAmount')}
              className={getClassNamesFor('reservedAmount')}
              style={{
                backgroundColor: '#ccff22',
                color: '#4a4a4a',
                borderRadius: '4px',
                textTransform: 'uppercase',
                border: 'none',
                boxShadow:
                  '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
              }}
            >
              <H3TableHeadStyle>Garantia Mobilizada</H3TableHeadStyle>
            </button>
          </th>
        </tr>
      </thead>
      <TableBody>
        {items.map(({ id, certificateId, plan, fundName, reservedAmount }) => (
          <tr key={id}>
            <TableCell css={cellTable}>{certificateId}</TableCell>
            <TableCell css={cellTable}>{plan}</TableCell>
            <TableCell css={cellTable}>{fundName}</TableCell>
            <TableCell css={cellTable}> R$ {reservedAmount}</TableCell>
          </tr>
        ))}
      </TableBody>
    </Table>
  )
}

function DataViewSimulation({ enabledSubmit }) {
  const { state } = useContext(ApplicationContext)

  const hasCertificates = !!state.certificates.length
  const validCertificates =
    state.certificates.length > 1 ||
    (state.certificates.length === 1 && !!state.certificates[0].certificateId)

  enabledSubmit(hasCertificates && validCertificates)

  return (
    <div className="DataViewSimulation">
      <ProductTable
        products={state.certificates.map(
          ({ certificateId, plan, fundName, reservedAmount }, index) => ({
            id: index + 1,
            certificateId,
            plan,
            fundName,
            reservedAmount,
          }),
        )}
      />
    </div>
  )
}

export { DataViewSimulation }
